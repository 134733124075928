@font-face {
	font-family: 'Inter';
	src: url('../assets/fonts/Inter-ExtraLight.ttf');
	font-weight: 100;
}
@font-face {
	font-family: 'Inter';
	src: url('../assets/fonts/Inter-Light.ttf');
	font-weight: 300;
}
@font-face {
	font-family: 'Inter';
	src: url('../assets/fonts/Inter-Medium.ttf');
	font-weight: 500;
}
@font-face {
	font-family: 'Inter';
	src: url('../assets/fonts/Inter-SemiBold.ttf');
	font-weight: 700;
}
@font-face {
	font-family: 'Inter';
	src: url('../assets/fonts/Inter-Bold.ttf');
	font-weight: 900;
}
@font-face {
	font-family: 'Poppins';
	src: url('../assets/fonts/Poppins-ExtraLight.ttf');
	font-weight: 100;
}
@font-face {
	font-family: 'Poppins';
	src: url('../assets/fonts/Poppins-Light.ttf');
	font-weight: 300;
}
@font-face {
	font-family: 'Poppins';
	src: url('../assets/fonts/Poppins-Medium.ttf');
	font-weight: 500;
}
@font-face {
	font-family: 'Poppins';
	src: url('../assets/fonts/Poppins-SemiBold.ttf');
	font-weight: 700;
}
@font-face {
	font-family: 'Poppins';
	src: url('../assets/fonts/Poppins-Bold.ttf');
	font-weight: 900;
}

:root {
	--max-width: 1100px;
	--border-radius: 12px;
	--font-mono: ui-monospace, Menlo, Monaco, 'Cascadia Mono', 'Segoe UI Mono',
		'Roboto Mono', 'Oxygen Mono', 'Ubuntu Monospace', 'Source Code Pro', 'Fira Mono',
		'Droid Sans Mono', 'Courier New', monospace;

	--background-start-rgb: 214, 219, 220;
	--background-end-rgb: 255, 255, 255;

	--primary-glow: conic-gradient(
		from 180deg at 50% 50%,
		#16abff33 0deg,
		#0885ff33 55deg,
		#54d6ff33 120deg,
		#0071ff33 160deg,
		transparent 360deg
	);
	--secondary-glow: radial-gradient(rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));

	--tile-start-rgb: 239, 245, 249;
	--tile-end-rgb: 228, 232, 233;
	--tile-border: conic-gradient(
		#00000080,
		#00000040,
		#00000030,
		#00000020,
		#00000010,
		#00000010,
		#00000080
	);

	--callout-rgb: 238, 240, 241;
	--callout-border-rgb: 172, 175, 176;
	--card-rgb: 180, 185, 188;
	--card-border-rgb: 131, 134, 135;

	/* SurveyJS CSS Variable Customization Start */
	--main-color: #69a6b4;
	--text-color: #49545b;
	--background-color: #f9f9fb;
	--font-family: 'Inter' !important;
	--font-family-secondary: 'Inter' !important;
	--sjs-font-family: 'Inter' !important;
	--sjs-primary-backcolor: #69a6b4;
	--sjs-primary-backcolor-light: rgba(105, 166, 180, 0.1);
	--sjs-secondary-backcolor: #69a6b4;
	--sjs-secondary-backcolor-light: rgba(105, 166, 180, 0.25);
	--sjs-secondary-backcolor-semi-light: rgba(105, 166, 180, 0.1);
	--sjs-general-backcolor-dim: #f9f9fb;
	--sjs-general-backcolor-dim-light: #f9f9fb;
	--sjs-general-forecolor: #49545b;
	--sjs-general-forecolor-light: #606e77;
	--sjs-border-default: #cddee7;
	--sjs-special-red: #a94943;
	--sjs-special-red-light: rgba(169, 73, 67, 0.1);
	--sjs-special-blue: #69a6b4;
	--sjs-special-blue-light: rgba(105, 166, 180, 0.1);
	--sjs-special-yellow: #f6a623;
	--sjs-special-yellow-light: rgba(246, 166, 35, 0.1);
	--sjs-primary-background-500: #69a6b4;
	--sjs-primary-background-10: rgba(105, 166, 180, 0.1);
	--sjs-secondary-background-500: #69a6b4;
	--sjs-secondary-background-10: rgba(105, 166, 180, 0.25);
	--sjs-secondary-background-25: rgba(105, 166, 180, 0.1);
	--sjs-layer-3-background-500: #f9f9fb;
	--sjs-layer-2-background-500: #f9f9fb;
	--sjs-layer-1-foreground-100: #49545b;
	--sjs-layer-1-foreground-50: #606e77;
	--sjs-border-25: #cddee7;
	--sjs-semantic-red-background-500: #a94943;
	--sjs-semantic-red-background-10: rgba(169, 73, 67, 0.1);
	--sjs-semantic-blue-background-500: #69a6b4;
	--sjs-semantic-blue-background-10: rgba(105, 166, 180, 0.1);
	--sjs-semantic-yellow-background-500: #f6a623;
	--sjs-semantic-yellow-background-10: rgba(246, 166, 35, 0.1);
	/* SurveyJS CSS Variable Customization End */
}

@media (prefers-color-scheme: light) {
	:root {
		--background-start-rgb: 0, 0, 0;
		--background-end-rgb: 0, 0, 0;

		--primary-glow: radial-gradient(rgba(1, 65, 255, 0.4), rgba(1, 65, 255, 0));
		--secondary-glow: linear-gradient(
			to bottom right,
			rgba(1, 65, 255, 0),
			rgba(1, 65, 255, 0),
			rgba(1, 65, 255, 0.3)
		);

		--tile-start-rgb: 2, 13, 46;
		--tile-end-rgb: 2, 5, 19;
		--tile-border: conic-gradient(
			#ffffff80,
			#ffffff40,
			#ffffff30,
			#ffffff20,
			#ffffff10,
			#ffffff10,
			#ffffff80
		);

		--callout-rgb: 20, 20, 20;
		--callout-border-rgb: 108, 108, 108;
		--card-rgb: 100, 100, 100;
		--card-border-rgb: 200, 200, 200;
	}
}

::-webkit-scrollbar {
	display: none;
	scroll-behavior: smooth;
}

.surveyContent ::-webkit-scrollbar {
	display: block;
}

#summaryContainer ::-webkit-scrollbar {
	display: block;
}

* {
	box-sizing: border-box;
	padding: 0;
	margin: 0;
}

html,
body {
	max-width: 100vw;
	overflow-x: hidden;
}

body {
	background-color: #f9f9fb;
}

a {
	color: inherit;
	text-decoration: none;
}

@media (prefers-color-scheme: light) {
	html {
		color-scheme: light;
	}
}

@keyframes buildingmove {
	0% {
		left: 0px;
	}

	15% {
		left: -120px;
	}

	35% {
		left: -120px;
	}

	50% {
		left: -240px;
	}

	70% {
		left: -240px;
	}

	85% {
		left: -360px;
	}

	100% {
		left: -360px;
	}
}

/* ------------------------------------------
				Calender view css
   ------------------------------------------ */

.rbc-header {
	padding: 16px 24px !important;
	color: #425057;
	font-family: 'Inter' !important;
	font-weight: 600 !important;
	font-size: 14px !important;
	line-height: 17px;
	border: none !important;
}
.rbc-month-view {
	border: 1px solid #cddee7;
}
.rbc-month-row {
	border-top: 1px solid #b6c9d3 !important;
}
.rbc-row-segment {
	padding: 0 8px !important;
	margin-bottom: 4px;
}
.rbc-event {
	display: flex;
	flex-direction: row;
	align-items: center;
	border: 1px solid #d67b75 !important;
	background-color: #ffe4e2 !important;
	font-size: 12px;
	border-radius: 2px !important;
	padding: 6px 8px !important	;
	position: relative;
}
.rbc-event-content {
	display: flex !important;
	font-family: 'Inter';
	font-style: normal;
	font-weight: 400 !important;
	font-size: 12px !important;
	line-height: 14.4px !important;
	color: #425057 !important;
	width: calc(100% - 26px);
}
.rbc-event-content::before {
	content: '\2022'; /* Unicode representation of the bullet symbol */
	font-size: 20px;
	color: #a94943;
	width: 8px;
	margin-right: 8px;
}
.rbc-event-content::after {
	content: ' \2550 ';
	width: 18px;
	font-size: 60px;
	color: #a94943;
	position: absolute;
	right: 8px;
	overflow: hidden;
}
.rbc-show-more {
	text-align: center;
	margin: auto;
	color: black;
	font-weight: 400;
}
.rbc-day-bg,
.rbc-button-link {
	padding: 8px;
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 21px;
	text-align: right;
	color: #425057 !important;
}
.rbc-off-range-bg {
	color: #b6c9d3 !important;
	background: white !important;
}
.rbc-date-cell {
	padding: 8px !important;
}
.rbc-day-bg + .rbc-day-bg {
	border-left: 1px solid #b6c9d3 !important;
}

.pac-container {
	z-index: 9999;
}

/* SurveyJS CSS Customization */

.svc-tab-designer {
	padding: 8px;
}

.svd-simulator-content {
	padding: 24px;
	max-width: 1050px;
	margin-left: auto;
	margin-right: auto;
}

.svc-tab-designer .svc-designer-header .sd-container-modern__title {
	padding: 16px !important;
}

.svc-question__content-actions .sv-action--convertTo .sv-action-bar-item__title::after {
	background-image: url('data:image/svg+xml,%3Csvg width=%2716%27 height=%2716%27 viewBox=%270 0 16 16%27 xmlns=%27http://www.w3.org/2000/svg%27%3E%3Cpath d=%27M8 11L13 6H3L8 11Z%27 fill=%27%2369a6b4%27/%3E%3C/svg%3E%0A') !important;
}

.sd-root-modern {
	--primary: #69a6b4;
}

.sd-body.sd-body--responsive {
	padding: 0 !important;
}

.sd-body.sd-body--static {
	padding: 0 !important;
	max-width: 100% !important;
}

.sd-title.sd-container-modern__title {
	padding-top: 0 !important;
	padding-left: 0 !important;
	padding-right: 0 !important;
	background-color: #f9f9fb !important;
}

.sd-title {
	font-size: 24px !important;
}

.sd-page__title {
	color: #49545b !important;
}

.sd-body .sd-body__page {
	padding-left: 0 !important;
	padding-right: 0 !important;
	background-color: #f9f9fb !important;
}

.sd-question__title {
	color: #49545b !important;
}

.sd-element__title span {
	font-size: 16px !important;
}

.sd-question__content {
	color: #49545b !important;
}

.sd-question__erbox {
	color: #a94943 !important;
}

.sd-question__description {
	color: #606e77 !important;
	font-size: 16px !important;
	display: block !important;
}

.sd-table__cell {
	color: #49545b !important;
	font-size: 16px !important;
}

.sd-action-bar {
	padding: 0 !important;
	background-color: #f9f9fb !important;
}

.sv-components-column.sv-components-container-contentBottom {
	margin-top: 16px !important;
}

.sd-action-bar.sd-footer.sd-body__navigation {
	justify-content: space-between;
	height: 40px;
}

.sv-action#sv-nav-next {
	margin-left: auto !important;
}

.sv-action#sv-nav-complete {
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
}

.sd-btn:hover {
	background-color: #2f7281 !important;
	color: #fff !important;
	box-shadow: unset !important;
}

.sd-btn:focus {
	background-color: #2f7281 !important;
	color: #fff !important;
	box-shadow: unset !important;
}

.sd-btn {
	background: #69a6b4 !important;
	color: #fff !important;
	font-size: 14px !important;
	padding: 8px 24px !important;
}

.sd-input {
	border: 1px solid #49545b !important;
	background-color: unset !important;
	border-radius: 8px !important;
	padding: 8px 40px 8px 8px !important;
	font-size: 14px !important;
	color: #49545b !important;
}

.sd-text {
	height: 41px !important;
}

.sd-dropdown {
	height: 41px !important;
}

.sd-input:hover {
	border: 1px solid #69a6b4 !important;
}

.sd-dropdown__value {
	font-size: 14px !important;
}
.sd-dropdown__value > input {
	font-size: 14px !important;
	line-height: unset !important;
}

.sd-dropdown__value > span {
	color: #49545b !important;
}

.sd-dropdown__value > input::placeholder {
	font-size: 14px !important;
	line-height: unset !important;
	color: #49545b !important;
}

.sd-list__item--selected > .sv-list__item-body {
	background: #69a6b4 !important;
}

.sd-comment__content {
	height: unset !important;
}

.sd-boolean__label {
	padding: 2px 8px 0 !important;
	font-size: 12px !important;
}

.sd-boolean__thumb {
	padding: 2px 8px 0 !important;
	font-size: 12px !important;
	height: 28px !important;
}

.sd-boolean {
	height: 35px !important;
}

.sd-boolean__thumb-ghost {
	align-self: center !important;
}

.sd-item__control-label {
	font-size: 14px !important;
}

.sd-multipletext__item-title {
	border-right: none !important;
}

.sa-question__title--draggable:after {
	background-image: url('data:image/svg+xml,%3Csvg version=%271.1%27 xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3E%3Cg%3E%3Cpolygon style=%27fill: %2369a6b4%27 points=%2713,5 12,6 13,7 9,7 9,3 10,4 11,3 8,0 5,3 6,4 7,3 7,7 3,7 4,6 3,5 0,8 3,11 4,10 3,9 7,9 7,13 6,12 5,13 8,16 11,13 10,12 9,13 9,9 13,9 12,10 13,11 16,8 %27/%3E%3C/g%3E%3C/svg%3E') !important;
}

.js-plotly-plot .plotly,
.js-plotly-plot .plotly div {
	display: flex !important;
	justify-content: center !important;
}

/*Editor css*/
.codox-styles .codox-session-info-list.vertical {
	position: fixed;
}

/* Set the top position for each avatar */
.codox-styles .codox-session-info-list.vertical .codox-session-info-avatar {
	top: calc((var(--avatar-index, 1) - 1) * 32px) !important;
}

/* Set the index for each avatar. If limit is exceeded, set the index up to 3 */
.codox-styles .codox-session-info-list.vertical .codox-session-info-avatar:nth-child(2) {
	--avatar-index: 2;
}
.codox-styles .codox-session-info-list.vertical .codox-session-info-avatar:nth-child(3) {
	--avatar-index: 3;
}

.ql-toolbar.ql-snow {
	border-color: #606e77 !important;
	border-radius: 10px 10px 0px 0px !important;
	border-bottom: none !important;
}

.quill:hover .ql-snow {
	border-color: #69a6b4 !important;
}

.ql-editor {
	font-family: 'Inter';
	font-size: 14px;
	background-color: #fff !important;
	border-radius: 10px;
}

.ql-container.ql-snow {
	border-color: #606e77 !important;
	border-radius: 0px 0px 10px 10px !important;
	border-top: none !important;
}

.ql-snow .ql-tooltip {
	left: 0 !important;
	box-shadow: 0px 0px 5px #ddd;
	border: 1px solid #ccc;
	padding: 5px 12px;
	background: #ccc;
}

.quill button:hover .ql-stroke,
.ql-picker-label:hover .ql-stroke {
	fill: none;
	stroke: #69a6b4 !important;
}

.ql-editor ul[data-checked='false'] > li::before {
	content: '' !important;
	width: 16px;
	height: 16px;
	background-color: white;
	border-radius: 50%;
	vertical-align: middle;
	border: 1px solid #c1c1c1;
	appearance: none;
	-webkit-appearance: none;
	outline: none;
	cursor: pointer;
}

.ql-editor ul[data-checked='true'] li::before {
	width: 16px;
	height: 16px;
	background-color: #67cb48;
	background-image: url('../assets/icons/WhiteCheckMarkIcon.svg');
	background-size: cover;
	border-radius: 50%;
	vertical-align: middle;
	text-align: center;
	line-height: 16px;
	border: 1px solid #67cb48;
	position: relative;
	content: '' !important;
}

.ql-editor ul[data-checked='true'] > li {
	text-decoration: line-through;
}

.ql-active .ql-stroke {
	fill: none;
	stroke: #69a6b4 !important;
}

.quill button:hover .ql-fill,
.ql-picker-label:hover .ql-fill {
	fill: #444 !important;
	stroke: none;
}

.ql-toolbar.ql-snow .ql-picker-label:hover {
	color: #444 !important;
}

.ql-snow.ql-toolbar .ql-picker-label.ql-active {
	color: #69a6b4 !important;
}

.ql-active .ql-fill {
	fill: #69a6b4 !important;
	stroke: none;
}

.ql-snow.ql-toolbar .ql-picker-item.ql-selected {
	color: #444 !important;
}

.ql-snow.ql-toolbar .ql-picker-item:hover {
	color: #444 !important;
}

.quill:not(:has(.ql-toolbar)) .ql-container.ql-snow {
	border-color: #606e77 !important;
	border-radius: 10px !important;
	border-top: 1px solid #606e77 !important;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

.MuiTooltip-tooltip {
	text-transform: capitalize;
}

.slick-slider {
	display: grid !important;
}

.slick-dots {
	position: absolute;
	top: -45px;
	right: -290px;
}

.slick-active .custom-dot {
	background-color: #69a6b4;
}

/* Override original readonly opacity of 0.25 */
.sv-dropdown-popup.sv-popup--overlay .sv-popup__button:disabled,
.sd-element__title--disabled,
.sd-question--disabled .sd-table__cell,
.sv-action-bar-item:disabled,
.sd-input.sd-input--disabled,
.sd-input.sd-input--disabled::placeholder,
.sd-item--disabled .sd-item__control-label,
.sv-button-group__item--disabled .sv-button-group__item-decorator,
.sd-ranking--disabled .sv-ranking-item__text,
.sv-ranking-item--disabled .sv-ranking-item__text,
.sd-rating__item-smiley.sd-rating__item-smiley--disabled,
.sd-rating__item-star--disabled,
.sd-boolean--disabled .sd-boolean__thumb,
.sd-boolean--disabled .sd-boolean__label,
.sd-btn:disabled,
.sd-action:disabled,
.sd-action--disabled,
.sd-context-btn:disabled {
	opacity: 0.7 !important;
}

.flatfile_iframe-wrapper.flatfile_displayAsModal {
	z-index: 1200 !important;
}
.flatfile_outer-shell {
	width: 100% !important;
	height: 100vh !important;
}
/* The "yes, cancel" button you see in the close modal */
.flatfile_primary {
	font-family: 'Inter' !important;
	border: 1px solid #69a6b4 !important;
	background-color: #69a6b4 !important;
	color: #fff;
}
/* The "no, stay" button you see in the close modal */
.flatfile_secondary {
	font-family: 'Inter' !important;
	color: #fff !important;
	background-color: #69a6b4;
	opacity: 0.5;
}
/* The heading text you see in the close modal */
.flatfile_modal-heading {
	font-family: 'Inter' !important;
	color: rgba(1, 65, 255, 0);
}
/* The description text you see in the close modal */
.flatfile_modal-text {
	font-family: 'Inter' !important;
	color: rgba(1, 65, 255, 0);
}
